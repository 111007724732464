import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/AdminPage.scss';
import AxiosInstance from '../AxiosInstance';

function AdminPage() {
  const [submissions, setSubmissions] = useState([]);
  const exampleData = [
    {
      nameSurname: 'John Doe',
      email: 'john.doe@example.com',
      phone: '+1234567890',
      nationality: 'American',
      placeOfBirth: 'New York City, NY',
      countryOfResidence: 'United States',
      currentPosition: 'Software Engineer',
      cv_file_name: 'john_doe_cv.pdf',
    },
    {
      nameSurname: 'Jane Smith',
      email: 'jane.smith@example.com',
      phone: '+9876543210',
      nationality: 'British',
      placeOfBirth: 'London, England',
      countryOfResidence: 'United Kingdom',
      currentPosition: 'Marketing Manager',
      cv_file_name: 'jane_smith_cv.pdf',
    },

  ];
  useEffect(() => {
    AxiosInstance.get('/records').then((response) => {
      setSubmissions(response.data);
    }).catch((error) => {
      console.error('error:', error);
      setSubmissions(exampleData);
    });
  }, []);

  return (
    <div className="admin-page container mt-5">
      <h1 className="admin-page__title text-center mb-4">Submitted Applications</h1>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name and Surname</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Nationality</th>
            <th>Place of Birth</th>
            <th>Country of Residence</th>
            <th>Current Position</th>
            <th>CV File</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, index) => (
            <tr key={index}>
              <td>{submission.nameSurname}</td>
              <td>{submission.email}</td>
              <td>{submission.phone}</td>
              <td>{submission.nationality}</td>
              <td>{submission.placeOfBirth}</td>
              <td>{submission.countryOfResidence}</td>
              <td>{submission.currentPosition}</td>
              <td>
                <a href={`http://localhost:4444/file/${submission.cv_file_name}`} target='_blank'>{submission.cv_file_name}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPage;
