import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import '../css/LoginPage.scss';
import axiosInstance from '../AxiosInstance';

function LoginPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("email:" + email + "password:" + password);
        axiosInstance.post('/login', {
            email: email,
            password: password
        }).then((response) => {
            if (response)
                navigate('/admin');
            localStorage.setItem('email', email);

        }).catch((error) => {
            console.log(error);
        });


    };



    return (

        <div className="form login-form">
            <h2 className="form-title">Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>

                <button type="submit" className="btn btn-primary w-100">Giriş Yap</button>
            </form>
        </div>


    )
}

export default LoginPage